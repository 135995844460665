<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">
        <v-alert
          :value="!$auth.user().premium"
          text
          type="info"
          class="mb-4"
        >
          <i18n path="premium_account_required">
            <template #premium_account>
              <router-link :to="{name: 'settings.business.subscription'}" class="inherit--text"><span class="font-weight-medium">{{ $t('premium_account').toLowerCase() }}</span></router-link>
            </template>
          </i18n>
        </v-alert>
        <data-table
          model="App\Models\User"
        >
          <template v-slot:empty-head>
            <v-icon size="72">mdi-account-tie-outline</v-icon>
          </template>
          <template v-slot:empty-text>
            <!-- <p class="subheading" v-html="$t('staff_description')"></p> -->
          </template>
        </data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
// import MenuBusinessSetting from '@/components/ui/MenuBusinessSetting.vue'
export default {
  // components: {
  //   MenuBusinessSetting
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: []
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
  }
}
</script>